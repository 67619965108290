import {useState, useEffect} from "react";
import Navbar from "./components/Navbar.js"
import QuizScreen from "./components/QuizScreen.js"
import JoinScreen from "./components/JoinScreen.js"
import "@aws-amplify/ui-react/styles.css";
import { listNotes } from "./graphql/queries";
import { API } from "aws-amplify";

import {
  withAuthenticator,
  //Button,
  //Heading,
  //Image,
  //View,
  //Card,
} from "@aws-amplify/ui-react";


function App({ signOut, user }) {    
  //const currentDate = new Date()
  //const startDate = new Date(currentDate.getFullYear(), 0, 1);
  //var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  //var wn = Math.ceil(days / 7) - 22;
  //var wn = 16;

  const [notes, setNotes] = useState([]);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const username = (user.attributes.email);
  
  function getStartTime() {
    const now = new Date()  
    const secondsSinceEpoch = Math.round(now.getTime() / 1000)
    return secondsSinceEpoch
  }


  const variables = {
    filter: {
      version: {
        eq: 15
      },
      score : {
        gt: 90
      }
    }
  };

  async function fetchNotes() {
    const apiData = await API.graphql({ query: listNotes, variables: variables });
    const notesFromAPI = apiData.data.listNotes.items;
    setNotes(notesFromAPI);
  }

  useEffect(() => {
    fetchNotes();
  }, []); 

  return (
    <>
      <Navbar/>
      <div className="quiz-container">
        {
          isQuizStarted ? (
            <QuizScreen retry={()=>setIsQuizStarted(false)} user={username} beginTime={getStartTime()}/>
          ) : (
            <JoinScreen start={()=>setIsQuizStarted(true)} />
          ) 
        }
      </div>
      <div className="indicator-container" id="indicator">
        Correct
      </div>
      <div className="logout-container">
        <button onClick={signOut}>Sign Out</button>
      </div>
    </>
  )
}

export default withAuthenticator(App);
